// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// URLから環境を取得する関数
const getEnvironmentFromUrl = () => {
  const pathSegments = window.location.pathname.split('/');
  const envIndex = pathSegments.indexOf('shared_clips_lists') + 1;
  const env = pathSegments[envIndex] || 'dev';
  console.log('Detected Environment:', env);
  return env;
};

// Firebaseの設定を取得する関数
const getFirebaseConfig = () => {
  const env = getEnvironmentFromUrl();
  console.log('Current Environment:', env);

  // 直接環境変数を取得
  let config;
  switch (env) {
    case 'dev':
      config = {
        apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
        authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.VUE_APP_FIREBASE_APP_ID
      };
      break;
    case 'stg':
      config = {
        apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
        authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.VUE_APP_FIREBASE_APP_ID
      };
      break;
    case 'prod':
      config = {
        apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
        authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.VUE_APP_FIREBASE_APP_ID
      };
      break;
    default:
      throw new Error(`Invalid environment: ${env}`);
  }

  // デバッグ用のログ出力
  console.log('Environment Variables:', {
    NODE_ENV: process.env.NODE_ENV,
    VUE_APP_ENV: process.env.VUE_APP_ENV,
    Config: {
      ...config,
      apiKey: '***hidden***'
    }
  });

  // 設定値の検証
  const requiredKeys = [
    'apiKey',
    'authDomain',
    'projectId',
    'storageBucket',
    'messagingSenderId',
    'appId'
  ];

  const missingKeys = requiredKeys.filter(key => !config[key]);
  if (missingKeys.length > 0) {
    console.error(`Missing required Firebase configuration keys:`, missingKeys);
    throw new Error(`Incomplete Firebase configuration for ${env} environment`);
  }

  return config;
};

let app;
let db;

try {
  const config = getFirebaseConfig();
  app = initializeApp(config);
  db = getFirestore(app);
} catch (error) {
  console.error('Firebase initialization error:', error);
  throw error;
}

export { app, db };