<!-- src/components/TagSettingModal.vue -->
<template>
  <div v-if="isOpen" class="modal-overlay">
    <div class="modal-content">
      <!-- ローディングオーバーレイを条件付きで表示 -->
      <LoadingOverlay 
        v-if="isImporting"
        :progress="loadingProgress"
        class="modal-loading"
      />

      <!-- 初期ローディング表示 -->
      <!-- <div v-if="isInitialLoading" class="loading-container">
        <img src="@/assets/loading_now_thinking_white.gif" alt="読み込み中" class="loading-gif">
        <span>タグを検索中...</span>
      </div> -->

      <!-- 完了モーダル -->
      <ImportCompleteModal
        v-if="showCompleteModal"
        @close="handleCompleteClose"
      />

      <!-- モーダルヘッダー -->
      <div class="modal-header">
        <div class="modal-title-container">
          <h2 class="modal-title">タグの設定</h2>
          <button class="close-button" @click="close">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <p class="modal-subtitle">
          タグを設定することでタグの簡単絞り込みができるようになります
        </p>
      </div>

      <!-- 選択済みタグ表示エリア -->
      <div class="selected-tags-scroll" v-if="selectedTags.length > 0">
        <div class="selected-tags">
          <div v-for="tag in selectedTags" :key="tag" class="tag-chip">
            <span class="hashtag">#</span>
            <span class="tag-text">{{ tag }}</span>
            <button class="remove-tag" @click="removeTag(tag)">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- タグ検索入力 -->
      <div class="search-input-container">
        <div class="search-input-wrapper">
          <i class="fas fa-search search-icon"></i>
          <input
            v-model="searchQuery"
            type="text"
            placeholder="タグを入力"
            class="search-input"
            @input="handleSearchInput"
          />
          <button v-if="searchQuery" class="clear-input" @click="clearSearch">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>

      <!-- タブ切り替え -->
      <div class="tabs-container">
        <div class="tabs">
          <button
            v-if="searchQuery"
            :class="['tab-button', { active: currentTab === 'search' }]"
            @click="currentTab = 'search'"
          >
            候補
          </button>
          <button
            v-if="hasRecentTags"
            :class="['tab-button', { active: currentTab === 'recent' }]"
            @click="currentTab = 'recent'"
          >
            最近使ったタグ
          </button>
          <button
            :class="['tab-button', { active: currentTab === 'ai' }]"
            @click="currentTab = 'ai'"
          >
            AIの提案
          </button>
          <button
            :class="['tab-button', { active: currentTab === 'trends' }]"
            @click="currentTab = 'trends'"
          >
            yahoo trends
          </button>
        </div>
      </div>

      <!-- タグリスト -->
      <div class="tag-list">
        <div v-if="isLoading || isTokenizerLoading" class="loading-container">
          <img src="@/assets/loading_now_thinking_white.gif" alt="読み込み中" class="loading-gif">
          <span>タグを検索中...</span>
        </div>
        <template v-else>
          <div 
            v-for="tag in filteredTags" 
            :key="tag.id" 
            class="tag-item"
            @click="selectTag(tag.name)"
            v-show="!selectedTags.includes(tag.name)"
          >
            <i class="fas fa-hashtag tag-icon"></i>
            <span class="tag-name">{{ tag.name }}</span>
          </div>
        </template>
      </div>

      <!-- 取り込みボタン -->
      <button class="import-button" @click="startImport" :disabled="isImporting">
        取り込みを開始する
      </button>

      <div class="tab-content">
        <div v-if="currentTab === 'candidate'">
          <!-- 候補タグの表示 -->
          <div class="tag-suggestions">
            <!-- ... existing candidate tags ... -->
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, getDoc, getDocs, query, orderBy, collection, where } from 'firebase/firestore';
import { db } from '@/firebase';
import kuromoji from 'kuromoji';
import LoadingOverlay from '@/components/Common/LoadingOverlay.vue';
import ImportCompleteModal from '@/components/Common/ImportCompleteModal.vue';

export default {
  name: 'TagSettingModal',
  components: {
    LoadingOverlay,
    ImportCompleteModal
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    clips: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      searchQuery: '',
      selectedTags: [],
      currentTab: 'ai',
      hasRecentTags: false,
      recentTags: [],
      trendTags: [],
      isLoading: false,
      searchResults: [],
      debounceTimeout: null,
      previousTab: 'ai',
      tokenizer: null,
      isTokenizerLoading: true,
      cachedAiTags: null,
      isInitializing: false,
      loadingProgress: 0,
      progressInterval: null,
      showCompleteModal: false,
      isInitialLoading: true,
      isImporting: false
    };
  },
  computed: {
    filteredTags() {
      if (this.currentTab === 'search') {
        return this.searchResults.map((tag, index) => ({
          id: `search-${index}`,
          name: tag,
          type: 'search'
        }));
      }

      switch (this.currentTab) {
        case 'recent':
          return this.recentTags;
        case 'ai':
          return this.cachedAiTags || [];
        case 'trends':
          return this.trendTags;
        default:
          return [];
      }
    }
  },
  watch: {
    searchQuery(newVal) {
      if (newVal) {
        this.currentTab = 'search';
      } else {
        this.currentTab = this.previousTab;
      }
    },
    currentTab(newTab) {
      if (newTab === 'ai' && !this.cachedAiTags && !this.isInitializing) {
        this.initializeTokenizer();
      }
    },
    clips: {
      immediate: true,
      handler(newClips) {
        if (newClips?.length > 0 && !this.cachedAiTags && !this.isInitializing) {
          this.initializeTokenizer();
        }
      }
    }
  },
  async created() {
    this.isInitialLoading = true;
    try {
      await this.initializeTokenizer();
      await Promise.all([
        this.fetchRecentTags(),
        this.fetchTrendTags()
      ]);
    } finally {
      this.isInitialLoading = false;
    }

    await new Promise(resolve => {
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, user => {
        if (user) {
          console.log('認証済みユーザー:', user.uid);
          this.$store.commit('setUser', {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName
          });
        } else {
          console.log('未認証状態');
          this.$store.commit('setUser', null);
        }
        unsubscribe();
        resolve();
      });
    });

    await this.fetchRecentTags();
    await this.fetchTrendTags();
    
    if (this.hasRecentTags) {
      this.currentTab = 'recent';
      this.previousTab = 'recent';
    }
  },
  methods: {
    async initializeTokenizer() {
      if (this.isInitializing) return;
      
      this.isInitializing = true;
      this.isTokenizerLoading = true;
      
      return new Promise((resolve, reject) => {
        try {
          kuromoji
            .builder({ dicPath: '/dict' })
            .build((err, tokenizer) => {
              if (err) {
                console.error('Tokenizer初期化エラー:', err);
                this.isTokenizerLoading = false;
                this.isInitializing = false;
                reject(err);
                return;
              }
              this.tokenizer = tokenizer;
              this.generateAiTags();
              this.isTokenizerLoading = false;
              this.isInitializing = false;
              resolve(tokenizer);
            });
        } catch (error) {
          console.error('Tokenizer初期化中の予期せぬエラー:', error);
          this.isTokenizerLoading = false;
          this.isInitializing = false;
          reject(error);
        }
      });
    },
    generateAiTags() {
      if (!this.tokenizer || !this.clips || this.clips.length === 0) {
        console.log('generateAiTags: データなし', {
          tokenizer: !!this.tokenizer,
          clips: this.clips,
          clipsLength: this.clips?.length
        });
        return;
      }

      const allTitles = this.clips
        .map(clip => clip.title || '')
        .join(' ');

      console.log('generateAiTags: 処理するタイトル', allTitles);

      const tokens = this.tokenizer.tokenize(allTitles);
      
      const words = tokens
        .filter(token => {
          const pos = token.pos;
          const posDetail = token.pos_detail_1;
          
          return (
            (pos === '名詞' && 
             !['助動詞語幹', '非自立', '代名詞', '数'].includes(posDetail)) ||
            (pos === '形容詞' && posDetail === '自立') ||
            (pos === '動詞' && posDetail === '自立')
          );
        })
        .map(token => token.basic_form)
        .filter(word => 
          word.length >= 2 && 
          !/^[a-zA-Z0-9]+$/.test(word) && 
          !['これ', 'それ', 'あれ', 'この', 'その', 'あの'].includes(word)
        );

      const uniqueWords = [...new Set(words)];
      
      this.cachedAiTags = uniqueWords.map((tag, index) => ({
        id: `ai-${index}`,
        name: tag,
        type: 'ai'
      }));
    },
    async fetchRecentTags() {
      console.log('開始: 最近使用したタグの取得処理');
      this.isLoading = true;

      try {
        const auth = getAuth();
        const user = auth.currentUser;
        console.log('認証状態:', user ? '認証済み' : '未認証');
        
        if (!user) {
          console.log('エラー: ユーザーが認証されていません');
          this.hasRecentTags = false;
          this.currentTab = 'ai';
          return;
        }

        console.log('ユーザーID:', user.uid);

        const userTagsDocRef = doc(db, 'user_tags', user.uid);
        const userTagsDoc = await getDoc(userTagsDocRef);
        
        console.log('user_tagsドキュメント状態:', userTagsDoc.exists() ? '存在します' : '存在しません');

        if (!userTagsDoc.exists()) {
          console.log('user_tagsドキュメントを作成します');
          await setDoc(userTagsDocRef, {
            created_at: new Date(),
            updated_at: new Date()
          });
          console.log('user_tagsドキュメント作成完了');
        }

        if (!this.recentTags.length) {
          console.log('user_tag_listの取得を開始');
          const userTagsRef = collection(db, 'user_tags', user.uid, 'user_tag_list');
          const q = query(userTagsRef, orderBy('used_at', 'desc'));
          const snapshot = await getDocs(q);

          this.recentTags = snapshot.docs.map(doc => {
            const data = doc.data();
            return {
              id: doc.id,
              name: data.user_tag_name,
              type: 'recent'
            };
          });

          this.hasRecentTags = this.recentTags.length > 0;
          console.log('最近使用したタグの存在:', this.hasRecentTags ? 'あり' : 'なし');
          console.log('最近使用したタグの取得完了:', this.recentTags);
        }

      } catch (error) {
        console.error('最近使用したタグの取得でエラーが発生:', error);
        this.hasRecentTags = false;
        this.currentTab = 'ai';
      } finally {
        this.isLoading = false;
        console.log('取得処理終了');
      }
    },

    async fetchTrendTags() {
      if (this.trendTags.length) {
        return;
      }

      console.log('開始: Yahoo Trendsタグの取得処理');
      this.isLoading = true;

      try {
        console.log('yahoo_trendsコレクションへの参照を作成');
        const trendsRef = collection(db, 'yahoo_trends');
        const excludedQuery = query(
          trendsRef,
          where('tag_excluded', '==', false)
        );
        const snapshot = await getDocs(excludedQuery);

        if (snapshot.empty) {
          console.log('警告: yahoo_trendsコレクションにデータが存在しません');
          this.trendTags = [];
          return;
        }

        let tags = snapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            name: data.tag_name,
            type: 'trend',
            rank: parseInt(data.rank_count) || 0
          };
        });

        tags.sort((a, b) => a.rank - b.rank);
        
        this.trendTags = tags;

      } catch (error) {
        console.error('Yahoo Trendsタグの取得でエラーが発生:', error);
        
        if (error.code === 'permission-denied') {
          console.error('アクセス権限がありません。Firebaseのセキュリティルールを確認してください。');
        } else if (error.code === 'not-found') {
          console.error('コレクションが存在しません。パスを確認してください。');
        } else {
          console.error('エラーの詳細:', error.code, error.message);
        }
        
        this.trendTags = [];
      } finally {
        this.isLoading = false;
        console.log('Yahoo Trendsタグの取得処理終了');
      }
    },

    handleSearchInput() {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      if (this.searchQuery && this.currentTab !== 'search') {
        this.previousTab = this.currentTab;
        this.currentTab = 'search';
      }

      this.debounceTimeout = setTimeout(() => {
        if (this.searchQuery) {
          this.searchTags();
        } else {
          this.searchResults = [];
        }
      }, 300);
    },

    async searchTags() {
      this.isLoading = true;
      try {
        const response = await fetch(
          `https://us-central1-keyocokyba-dev.cloudfunctions.net/searchDocuments?q=${encodeURIComponent(this.searchQuery)}&page=1&limit=20&env=dev`,
          {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            mode: 'cors'
          }
        );
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        let searchResults = data.map(item => item._source?.tag_name).filter(Boolean);
        
        if (this.searchQuery && !searchResults.includes(this.searchQuery)) {
          searchResults.unshift(this.searchQuery);
        }

        this.searchResults = searchResults;
        this.currentTab = 'search';
      } catch (error) {
        console.error('タグ検索でエラー:', error);
        this.searchResults = [this.searchQuery];
      } finally {
        this.isLoading = false;
      }
    },

    selectTag(tag) {
      if (!this.selectedTags.includes(tag)) {
        this.selectedTags.push(tag);
        this.searchQuery = '';
      }
    },

    removeTag(tagName) {
      this.selectedTags = this.selectedTags.filter(t => t !== tagName);
    },

    clearSearch() {
      this.searchQuery = '';
      this.searchResults = [];
      this.currentTab = this.previousTab;
    },

    close() {
      if (this.progressInterval) {
        clearInterval(this.progressInterval);
      }
      this.loadingProgress = 0;
      this.$emit('close');
    },

    async startImport() {
      if (this.isImporting) {
        console.log('既にインポート処理が実行中です');
        return;
      }

      console.log('----------------------------------------');
      console.log('TagSettingModal: インポート処理開始');
      this.isImporting = true;
      this.loadingProgress = 0;

      // プログレスバーの更新を開始
      const progressInterval = setInterval(() => {
        if (this.loadingProgress < 90) {
          this.loadingProgress += Math.random() * 10;
          console.log(`TagSettingModal: ローディング進捗 ${this.loadingProgress.toFixed(1)}%`);
        }
      }, 200);

      try {
        console.log('1. LoadingOverlay表示開始:', new Date().toISOString());
        
        console.log('2. インポート処理の開始を待機');
        // インポート処理の完了を待つ
        await new Promise((resolve) => {
          console.log('3. importイベントを発火');
          this.$emit('import', this.selectedTags, () => {
            console.log('4. インポート処理が完了');
            resolve();
          });
        });

        console.log('5. 最低表示時間(4秒)の待機開始');
        // インポート処理完了後、最低表示時間を確保（10秒から4秒に変更）
        await new Promise(resolve => setTimeout(resolve, 4000));
        console.log('6. 最低表示時間の待機完了');
        
        // インポート完了後、プログレスバーを100%にする
        clearInterval(progressInterval);
        this.loadingProgress = 100;
        console.log('7. プログレスバーを100%に設定');
        console.log('8. LoadingOverlay表示終了:', new Date().toISOString());
        
        // LoadingOverlayを非表示にしてImportCompleteModalを表示
        console.log('9. isImportingをfalseに設定');
        this.isImporting = false;
        console.log('10. showCompleteModalをtrueに設定');
        this.showCompleteModal = true;
        console.log('11. ImportCompleteModal表示開始:', new Date().toISOString());
        
        console.log('12. ImportCompleteModalの表示時間(3秒)待機開始');
        // ImportCompleteModalを4秒間表示
        await new Promise(resolve => setTimeout(resolve, 3000));
        console.log('13. ImportCompleteModalの表示時間待機完了');
        
        console.log('14. showCompleteModalをfalseに設定');
        this.showCompleteModal = false;
        
        console.log('15. closeイベントを発火');
        // 全ての処理が完了してから親コンポーネントに通知
        this.$emit('close');
        console.log('16. 全処理完了');
        console.log('----------------------------------------');

      } catch (error) {
        console.error('エラー発生:', error);
        console.log('エラーによる処理中断');
        clearInterval(progressInterval);
        this.isImporting = false;
        console.log(`エラーによりLoadingOverlay終了: ${new Date().toISOString()}`);
        console.log('----------------------------------------');
      }
    },
    handleCompleteClose() {
      this.showCompleteModal = false;
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  width: 90%;
  max-width: 500px;
  border-radius: 12px;
  padding: 20px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  margin-bottom: 20px;
}

.modal-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 12px;
}

.modal-title {
  font-size: 20px;
  color: #3C3C3C;
  margin: 0;
  font-weight: bold;
  text-align: center;
}

.close-button {
  position: absolute;
  right: 0;
  background: none;
  border: none;
  color: #6C6A6C;
  cursor: pointer;
  padding: 8px;
}

.modal-subtitle {
  font-size: 14px;
  color: #6C6A6C;
  text-align: center;
  margin: 0;
}

.selected-tags-scroll {
  margin-bottom: 16px;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.selected-tags {
  display: inline-flex;
  gap: 8px;
  padding: 4px;
}

.tag-chip {
  background: #F8F9FA;
  border: 1px solid #31649F;
  border-radius: 16px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
}

.hashtag {
  color: #31649F;
}

.tag-text {
  color: #6C6A6C;
}

.remove-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border: 1px solid #6C6A6C;
  border-radius: 50%;
  background: none;
  color: #6C6A6C;
  cursor: pointer;
  padding: 0;
  font-size: 8px;
}

.search-input-container {
  margin-bottom: 20px;
}

.search-input-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #E7E7E7;
  border-radius: 24px;
  padding: 8px 16px;
  background: #F8F9FA;
}

.search-icon {
  color: #6C6A6C;
  margin-right: 8px;
}

.search-input {
  flex: 1;
  border: none;
  background: transparent;
  outline: none;
  font-size: 14px;
  color: #3C3C3C;
}

.clear-input {
  background: none;
  border: none;
  color: #6C6A6C;
  cursor: pointer;
  padding: 4px;
}

.tabs-container {
  overflow-x: auto;
  margin-bottom: 16px;
  border-bottom: 1px solid #E7E7E7;
}

.tabs {
  display: inline-flex;
  gap: 16px;
  padding-bottom: 1px;
  min-width: min-content;
}

.tab-button {
  background: none;
  border: none;
  padding: 8px 16px;
  color: #6C6A6C;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  min-width: max-content;
}

.tab-button.active {
  color: #31649F;
}

.tab-button.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #31649F;
}

.tag-list {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 40px 20px;
  color: #6C6A6C;
}

.loading-gif {
  width: 40px;
  height: 40px;
}

.tag-item {
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  border-bottom: 1px solid #E7E7E7;
}

.tag-item:last-child {
  border-bottom: none;
}

.tag-icon {
  color: #6C6A6C;
  margin-right: 12px;
}

.tag-name {
  color: #3C3C3C;
  font-size: 14px;
}

.import-button {
  width: 100%;
  background-color: #31649F;
  color: white;
  border: none;
  border-radius: 24px;
  padding: 12px;
  font-size: 16px;
  cursor: pointer;
}

.import-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

@media (max-width: 480px) {
  .modal-content {
    width: 95%;
    height: auto;
    max-height: 90vh;
    border-radius: 12px;
    margin: 20px;
    padding: 16px;
  }

  .modal-title {
    font-size: 18px;
  }

  .modal-subtitle {
    font-size: 12px;
  }

  .search-input {
    font-size: 12px;
  }

  .tag-name {
    font-size: 12px;
  }

  .import-button {
    font-size: 14px;
    padding: 10px;
  }

  .tab-button {
    font-size: 12px;
    padding: 8px 12px;
  }

  .tag-chip {
    padding: 2px 8px;
  }

  .tag-text {
    font-size: 12px;
  }

  .remove-tag {
    width: 14px;
    height: 14px;
    font-size: 7px;
  }

  .search-input-wrapper {
    padding: 6px 12px;
  }

  .tag-list {
    max-height: 250px;
  }

  .tag-item {
    padding: 10px;
  }

  .loading-container {
    padding: 16px;
    font-size: 12px;
  }
}

/* ローディングオーバーレイのスタイルを追加 */
.modal-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  z-index: 1001;
}
</style>