<!-- src/pages/SigninComplete.vue -->
<template>
  <div class="page-container">
    <!-- ヘッダー -->
    <header class="fixed-header">
      <div class="header-logo-container">
        <img src="@/assets/keyocokyba_logotype.png" alt="KeyocOkyba" class="header-logo">
      </div>
    </header>

    <!-- メインコンテンツ -->
    <div class="signin-complete-container">
      <div class="content-wrapper">
        <!-- サムネイル部分 -->
        <div class="thumbnail-section">
          <div class="thumbnail-container">
            <img src="@/assets/signin_complete_thumbnail.png" alt="サインイン完了" class="thumbnail">
          </div>
        </div>

        <!-- メッセージ部分 -->
        <div class="message-section">
          <h1 class="title">サインインが完了しました</h1>
          <p class="description">自動的に画面が切り替わった後にタグを設定して記憶を取り込んでください</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SigninComplete',
  created() {
    // 即時リダイレクト
    const redirectUrl = this.$route.query.redirect;
    if (redirectUrl) {
      // fromSignInCompleteパラメータを追加
      if (redirectUrl.includes('?')) {
        window.location.href = `${redirectUrl}&fromSignInComplete=true`;
      } else {
        window.location.href = `${redirectUrl}?fromSignInComplete=true`;
      }
    } else {
      this.$router.replace('/');
    }
  }
}
</script>

<style scoped>
.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 1000;
  height: 66px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E5E5E5;
}

.header-logo-container {
  padding: 0 20px;
}

.header-logo {
  height: 32px;
  width: auto;
}

.signin-complete-container {
  padding-top: 66px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-wrapper {
  width: 90%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  gap: 48px;
}

.thumbnail-section {
  flex: 1;
  max-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnail-container {
  width: 70%;
  position: relative;
}

.thumbnail-container::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.message-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.title {
  font-size: 24px;
  color: #3C3C3C;
  margin-bottom: 24px;
}

.description {
  font-size: 16px;
  color: #6C6A6C;
  line-height: 1.5;
}

/* タブレット向けスタイル */
@media (max-width: 1024px) {
  .content-wrapper {
    flex-direction: column;
    padding: 20px;
  }

  .thumbnail-section {
    max-width: 100%;
    width: 100%;
  }

  .thumbnail-container {
    width: 70%;
  }

  .message-section {
    margin-top: 24px;
  }

  .title {
    font-size: 20px;
  }

  .description {
    font-size: 14px;
  }
}

/* スマートフォン向けスタイル */
@media (max-width: 480px) {
  .fixed-header {
    height: 50px;
  }

  .header-logo {
    height: 24px;
  }

  .signin-complete-container {
    padding-top: 50px;
  }

  .content-wrapper {
    padding: 16px;
  }

  .thumbnail-container {
    width: 80%;
  }

  .title {
    font-size: 18px;
    margin-bottom: 16px;
  }

  .description {
    font-size: 12px;
  }
}
</style>