<template>
  <div class="modal-overlay">
    <div class="modal-content">
      <div class="loading-content">
        <div class="loading-text">記憶を取り込んでいます</div>
        <div class="loading-subtext">※取り込みが終わるまで画面を閉じないでください。</div>
        <div class="loading-subtext">正常に取り込みが行われない可能性があります。</div>
        <img src="@/assets/loading_now_loading_white.gif" alt="読み込み中" class="loading-gif">
        <div class="progress-container">
          <div class="progress-bar">
            <div class="progress" :style="{ width: `${Math.floor(progress)}%` }"></div>
          </div>
          <div class="progress-text">{{ Math.floor(progress) }}%完了</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingOverlay',
  props: {
    progress: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  width: 90%;
  max-width: 500px;
  border-radius: 12px;
  padding: 20px;
  max-height: 90vh;
  overflow-y: auto;
}

.loading-content {
  text-align: center;
  padding: 24px;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.loading-text {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
}

.loading-subtext {
  color: #666;
  font-size: 12px;
  line-height: 1.4;
}

.loading-gif {
  width: 80px;
  height: 80px;
  margin: 24px 0;
}

.progress-container {
  width: 100%;
}

.progress-bar {
  width: 100%;
  height: 4px;
  /* background: #E0E0E0; */
  border-radius: 2px;
  overflow: hidden;
  margin-bottom: 8px;
}

.progress {
  height: 100%;
  background: #31649F;
  transition: width 0.3s ease;
}

.progress-text {
  color: #666;
  font-size: 12px;
  text-align: center;
}

@media (max-width: 480px) {
  .modal-content {
    width: 95%;
    margin: 20px;
    padding: 16px;
  }

  .loading-content {
    padding: 16px;
  }
}
</style> 