<template>
  <div class="page-container">
    <!-- ヘッダーとして固定されるロゴタイプ -->
    <header class="fixed-header">
      <div class="header-logo-container">
        <img src="@/assets/keyocokyba_logotype.png" alt="KeyocOkyba" class="header-logo">
      </div>
    </header>

    <!-- メインコンテンツ -->
    <div class="signup-container">
      <!-- スマホ/タブレット用タイトル -->
      <h1 class="title mobile-title">サインアップ</h1>

      <!-- サムネイル部分 -->
      <div class="thumbnail-section">
        <div class="thumbnail-container">
          <img src="@/assets/signup_thumbnail.png" alt="サインアップ" class="thumbnail">
        </div>
      </div>

      <!-- 認証部分 -->
      <div class="auth-section">
        <!-- PC用タイトル -->
        <h1 class="title desktop-title">サインアップ</h1>

        <div class="auth-buttons">
          <button class="auth-button google" @click="signInWithGoogle" :disabled="loading">
            <img src="@/assets/google-logo.svg" alt="Google" class="provider-icon">
            <span>Sign in with Google</span>
          </button>
          <button class="auth-button apple" @click="signInWithApple" :disabled="loading">
            <img src="@/assets/apple-logo.svg" alt="Apple" class="provider-icon">
            <span>Sign in with Apple</span>
          </button>
        </div>

        <div class="signup-link">
          <span class="text-gray">アカウントをお持ちですか？</span>
          <router-link to="/signin" class="link">サインイン</router-link>
          <div class="help-container">
            <i class="fas fa-question-circle help-icon"></i>
            <span class="help-text">ヘルプ</span>
          </div>
        </div>

        <div class="terms">
          <span class="text-gray">サインインにより、私は</span>
          <router-link to="/privacy-policy" class="link">KeyocOkybaのプライバシーポリシー</router-link>
          <span class="text-gray">と</span>
          <router-link to="/terms-of-use" class="link">サービス利用規約</router-link>
          <span class="text-gray">に同意します。</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { doc, setDoc, getDoc, getFirestore, serverTimestamp } from 'firebase/firestore';

export default {
  name: 'SignUp',
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async createUserDocument(user) {
      const db = getFirestore();
      const userRef = doc(db, 'users', user.uid);
      
      // 既存のドキュメントをチェック
      const docSnap = await getDoc(userRef);
      
      // ドキュメントが存在しない場合のみ作成
      if (!docSnap.exists()) {
        const userData = {
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
          notification_setting: {
            radius: 1000,
            send_notification: true
          },
          profile: {
            created_at: serverTimestamp(),
            email: user.email,
            language_type: 0,
            medal_status: 0,
            name: user.displayName,
            photo_url: user.photoURL,
            updated_at: serverTimestamp()
          },
          tags: {}
        };

        try {
          await setDoc(userRef, userData);
          console.log('新規ユーザードキュメント作成:', userData);
        } catch (error) {
          console.error('ユーザードキュメント作成エラー:', error);
        }
      }
    },

    async signInWithGoogle() {
      if (this.loading) return;
      this.loading = true;

      try {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        
        // ユーザー情報を作成
        const userData = {
          uid: result.user.uid,
          email: result.user.email,
          displayName: result.user.displayName,
          photoURL: result.user.photoURL
        };
        
        // Vuexストアとローカルストレージを更新
        this.$store.commit('setUser', userData);
        localStorage.setItem('auth_user', JSON.stringify(userData));

        // 新規ユーザーの場合のみドキュメントを作成
        await this.createUserDocument(result.user);

        // リダイレクト処理
        if (this.$route.query.redirect) {
          window.location.href = this.$route.query.redirect;
        } else {
          this.$router.push('/');
        }
      } catch (error) {
        console.error('Googleサインインエラー:', error);
      } finally {
        this.loading = false;
      }
    },

    async signInWithApple() {
      if (this.loading) return;
      this.loading = true;

      try {
        const auth = getAuth();
        const provider = new OAuthProvider('apple.com');
        
        // メールスコープを明示的に要求
        provider.addScope('email');
        
        const result = await signInWithPopup(auth, provider);
        
        // OAuthCredentialから追加情報を取得
        const credential = OAuthProvider.credentialFromResult(result);
        const appleInfo = credential?.additionalUserInfo?.profile;
        
        // ユーザー情報を作成（Apple認証情報から取得）
        const userData = {
          uid: result.user.uid,
          email: result.user.email || appleInfo?.email || 'Apple User',
          displayName: result.user.displayName || appleInfo?.name?.firstName || 'Apple User',
          photoURL: result.user.photoURL || ''
        };
        
        // Vuexストアとローカルストレージを更新
        this.$store.commit('setUser', userData);
        localStorage.setItem('auth_user', JSON.stringify(userData));

        // 新規ユーザーの場合のみドキュメントを作成
        await this.createUserDocument({
          ...result.user,
          email: userData.email,
          displayName: userData.displayName,
          photoURL: userData.photoURL
        });

        if (this.$route.query.redirect) {
          window.location.href = this.$route.query.redirect;
        } else {
          this.$router.push('/');
        }
      } catch (error) {
        console.error('Appleサインインエラー:', error);
      } finally {
        this.loading = false;
      }
    },

    onAuthSuccess() {
      // リダイレクト先のURLを取得（存在しない場合はホームページへ）
      const redirectUrl = this.$route.query.redirect || '/';
      
      // 直接元のURLへリダイレクト
      window.location.href = redirectUrl;
    }
  }
}
</script>
  
  <style scoped>
  .page-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    }
  
  .fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: white;
    z-index: 1000;
    height: 66px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
  }
  
  .header-logo-container {
    padding: 0 20px;
  }
  
  .header-logo {
    height: 32px;
    width: auto;
  }
  
  .signup-container {
    padding-top: 66px; /* ヘッダーの高さ分のパディング */
    width: 90%;
    display: flex;
    flex-direction: column;
    flex: 1; /* これを追加 */
    }
  
  .title {
    font-size: 24px;
    color: #3C3C3C;
    margin: 24px 0;
    text-align: center;
  }
  
  .mobile-title {
    display: none;
  }
  
  .thumbnail-section {
    width: 100%;
    margin: 0 auto;
  }
  
  .thumbnail-container {
    width: 100%;
    position: relative;
    padding-top: 75%;
  }
  
  .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .auth-section {
    width: 100%;
    max-width: 400px;
    margin: 24px auto;
  }
  
  .auth-buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
  }
  
  .auth-button {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    cursor: pointer;
    font-weight: bold;
    height: 48px;
  }
  
  .auth-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .auth-button.google {
    background-color: white;
    color: #6C6A6C;
    border: 1px solid #E7E7E7;
  }
  
  .auth-button.apple {
    background-color: black;
    color: white;
    border: none;
  }
  
  .provider-icon {
    width: 20px;
    height: 20px;
  }
  
  .signup-link {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    margin-bottom: 16px;
    flex-wrap: wrap;
  }
  
  .help-container {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-left: 8px;
  }
  
  .help-icon {
    color: #31649F;
  }
  
  .help-text {
    color: #31649F;
  }
  
  .text-gray {
    color: #6C6A6C;
  }
  
  .link {
    color: #31649F;
    text-decoration: none;
  }
  
  .terms {
    text-align: center;
    font-size: 12px;
    line-height: 1.5;
    padding: 0 16px;
  }
  
  /* PC表示用のスタイル */
  @media (min-width: 1025px) {
    .page-container {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .signup-container {
        flex-direction: row;
        align-items: center;
        gap: 48px;
        max-width: 1200px;
        margin: auto; /* margin: 0 auto から変更 */
        padding: 0 20px; /* パディングを追加 */
        min-height: calc(100vh - 66px); /* 全体の高さからヘッダーの高さを引く */
        justify-content: center; /* 中央揃えを追加 */
    }
  
    .mobile-title {
      display: none;
    }
  
    .desktop-title {
      display: block;
    }
  
    .thumbnail-section {
        flex: 1;
        max-width: 50%;
    }

    .thumbnail-container {
        padding-top: 66.67%; /* 3:2の比率に調整 */
    }

    .auth-section {
        flex: 1;
        margin: 0;
        max-width: 400px;
    }

    .desktop-title {
        text-align: left;
        margin-top: 0; /* タイトルのマージンを調整 */
    }
  
    .terms {
      text-align: left;
    }
  }
  
  /* タブレットとスマートフォン共通のスタイル */
  @media (max-width: 1024px) {
    .fixed-header {
      height: 50px;
    }
  
    .header-logo {
      height: 24px;
    }
  
    .signup-container {
      padding-top: 50px;
    }
  
    .mobile-title {
      display: block;
      font-size: 20px;
      margin: 16px 0;
    }
  
    .desktop-title {
      display: none;
    }
  
    .thumbnail-section {
      margin: 0 auto 24px;
    }
  
    .auth-button {
      height: 40px;
      font-size: 14px;
    }
  
    .terms {
      font-size: 10px;
    }
  
    .signup-link {
      font-size: 12px;
    }
  }
  
  /* スマートフォン特有の微調整 */
  @media (max-width: 480px) {
    .header-logo {
      height: 20px;
    }
  
    .thumbnail-container {
      padding-top: 100%; /* より正方形に近い比率 */
    }
  
    .auth-section {
      padding: 0 16px;
    }
  }
  </style>