import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import en from '../locales/en.json'
import ja from '../locales/ja.json'
import ko from '../locales/ko.json'
import './firebase'
import { getAuth, signOut } from 'firebase/auth'  // signOutを追加

Vue.use(VueI18n)

// ========== 一時的なリセット機能（後で削除） ==========
const resetAuthState = async () => {
  try {
    // Firebase認証のサインアウト
    const auth = getAuth();
    await signOut(auth);
    
    // Vuexストアのリセット
    store.commit('setUser', null);
    store.commit('setLoading', false);
    
    // LocalStorageのクリア
    localStorage.removeItem('auth_user');
    
    console.log('===== Auth State Reset =====');
    console.log('Firebase: Signed out');
    console.log('Vuex Store:', store.state);
    console.log('LocalStorage auth_user:', localStorage.getItem('auth_user'));
    console.log('==========================');
  } catch (error) {
    console.error('リセット中にエラーが発生:', error);
  }
};

// デバッグ用にグローバルに公開（後で削除）
window.resetAuthState = resetAuthState;
// ================================================

// 既存のコード
const browserLanguage = (navigator.language || navigator.userLanguage).split('-')[0];
const supportedLanguages = ['en', 'ja', 'ko'];
const userLanguage = supportedLanguages.includes(browserLanguage) ? browserLanguage : 'en';

const i18n = new VueI18n({
  locale: userLanguage,
  fallbackLocale: 'en',
  messages: { en, ja, ko }
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')