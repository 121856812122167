import Vue from 'vue'
import Router from 'vue-router'
import PrivacyPolicy from '@/pages/PrivacyPolicy'
import HowToUseEn from '@/pages/HowToUseEn'
import HowToUseJa from '@/pages/HowToUseJa'
import HowToUseKo from '@/pages/HowToUseKo'
import HomeScreen from '@/pages/HomeScreen'
import DetailLocation from '@/pages/DetailLocation'
import ContactUs from '@/pages/ContactUs'
import TermsOfUse from '@/pages/TermsOfUse'
import SharedList from '@/pages/SharedList'
import SignUp from '@/pages/SignUp'
import SignIn from '@/pages/SignIn'
import store from '@/store'
import SigninComplete from '@/pages/SigninComplete'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'HomeScreen',
      component: HomeScreen
    },
    {
      path: '/contact-us',
      name: 'ContactUs',
      component: ContactUs
    },
    {
      path: '/terms-of-use',
      name: 'TermsOfUse',
      component: TermsOfUse
    },
    {
      path: '/detail-location',
      name: 'DetailLocation',
      component: DetailLocation
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy
    },
    {
      path: '/how-to-use',
      name: 'HowToUseEn',
      component: HowToUseEn
    },
    {
      path: '/how-to-use/ja',
      name: 'HowToUseJa',
      component: HowToUseJa
    },
    {
      path: '/how-to-use/ko',
      name: 'HowToUseKo',
      component: HowToUseKo
    },
    {
      path: '/shared_clips_lists/:environment/:id',
      name: 'SharedList',
      component: SharedList
    },
    {
      path: '/signup',
      name: 'SignUp',
      component: SignUp
    },
    {
      path: '/signin',
      name: 'SignIn',
      component: SignIn
    },
    {
      path: '/signin-complete',
      name: 'SigninComplete',
      component: SigninComplete
    }
  ]
})

// グローバルナビゲーションガード
router.beforeEach((to, from, next) => {
  // ページ遷移時に認証状態をチェック
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  
  if (requiresAuth && !store.getters.isAuthenticated) {
    // 認証が必要なページで未認証の場合
    next({
      path: '/signin',
      query: { redirect: to.fullPath }
    })
  } else {
    next()
  }
});

export default router