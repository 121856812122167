<!-- src/pages/SharedList.vue -->
<template>
  <div class="shared-list">
    <!-- ローディング表示 -->
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-container">
        <img src="@/assets/loading_now_thinking_white.gif" alt="読み込み中" class="loading-gif">
        <span>読み込み中...</span>
      </div>
    </div>

    <template v-else>
      <!-- FontAwesome CDN -->
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">

      <!-- ヘッダーバー -->
      <HeaderBar />
      
      <div class="shared-list-content" v-if="sharedList">
        <!-- サムネイル画像 -->
        <div class="thumbnail">
          <img :src="sharedList.thumbnail_url" alt="サムネイル" class="thumbnail-image">
        </div>

        <!-- リスト名 -->
        <h1 class="list-name">{{ sharedList.list_name }}</h1>

        <!-- ユーザー情報 -->
        <div class="user-info">
          <img :src="sharedList.owner_photo_url" alt="プロフィール" class="profile-image">
          <div class="user-details">
            <div class="owner-name">{{ sharedList.owner_name }}</div>
            <div class="import-count">{{ sharedList.imported_user_ids.length }}人がこのリストを取り込んでいます</div>
          </div>
        </div>

        <!-- アクションボタン -->
        <div class="action-buttons">
          <button class="import-button" @click="handleImportClick">
            <i class="fas fa-download"></i>
            自分の記憶として取り込む
          </button>
          <div class="about-link" @click="goToHowToUse">
            <i class="fas fa-question-circle"></i>
            <span class="about-text">キオクオキバとは</span>
          </div>
        </div>

        <!-- クリップリスト -->
        <div class="clips-container">
          <div v-for="clip in clips" 
              :key="clip.original_clip_id" 
              class="clip-item"
              @click="openClipDetail(clip)"
            >
            <div class="clip-image-container">
              <div class="clip-image">
                <img 
                  :src="getImageUrl(clip.url)" 
                  :alt="clip.title"
                  @error="handleImageError"
                >
              </div>
              <div class="evaluation" :class="{ 'center-align': clip.triggers.location.evaluation_score === 0 }">
                <template v-if="clip.triggers.location.evaluation_score > 0">
                  <div class="star-icons-container">
                    <img 
                      v-for="n in 5"
                      :key="n"
                      :src="require(`@/assets/${n <= clip.triggers.location.evaluation_score ? 'star_gold.svg' : 'star.svg'}`)" 
                      :alt="n <= clip.triggers.location.evaluation_score ? 'filled star' : 'empty star'"
                      class="star-icon"
                    />
                  </div>
                  <span class="evaluation-score">({{ clip.triggers.location.evaluation_score }})</span>
                </template>
                <template v-else>
                  <span class="no-evaluation">(評価なし)</span>
                </template>
              </div>
            </div>
            <div class="clip-content">
              <h3 class="clip-title">{{ clip.title }}</h3>
              <p class="clip-body">{{ clip.body }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- タグ設定モーダル -->
      <TagSettingModal
        v-if="isTagModalOpen"
        :isOpen="isTagModalOpen"
        :clips="clips"
        @close="isTagModalOpen = false"
        @import="handleImport"
      />

      <!-- クリップ詳細シート -->
      <ClipDetailSheet
        v-if="selectedClip"
        :isOpen="isDetailSheetOpen"
        @close="closeClipDetail"
        @toggle-notification="toggleNotification"
        :clip="selectedClip"
      />
      
      <FooterBar />
    </template>
  </div>
</template>

<script>
import { db } from '@/firebase'
import { collection, doc, getDoc, getDocs, updateDoc, arrayUnion, setDoc, serverTimestamp } from 'firebase/firestore'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import FooterBar from '@/components/Common/FooterBar.vue'
import HeaderBar from '@/components/Common/HeaderBar.vue'
import ClipDetailSheet from '@/components/ClipDetailSheet.vue'
import TagSettingModal from '@/components/TagSettingModal.vue'
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage'

export default {
  name: 'SharedList',
  components: {
    FooterBar,
    HeaderBar,
    ClipDetailSheet,
    TagSettingModal
  },
  data() {
    return {
      isLoading: true,
      sharedList: null,
      clips: [],
      selectedClip: null,
      isDetailSheetOpen: false,
      isTagModalOpen: false,
      loadingProgress: 0
    }
  },
  async created() {
    try {
      // 初期ローディングフラグを設定
      this.isLoading = true;
      console.log('SharedList: 初期ローディング表示');
      
      // 認証状態の確認を待つ
      await new Promise(resolve => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, user => {
          if (user) {
            console.log('認証済みユーザー:', user.uid);
            this.$store.commit('setUser', {
              uid: user.uid,
              email: user.email,
              displayName: user.displayName
            });
          } else {
            console.log('未認証状態');
            this.$store.commit('setUser', null);
          }
          unsubscribe();
          resolve();
        });
      });

      // データ取得を確実に実行
      await this.fetchSharedList();
      
      // データ取得後、認証状態とページ読み込みタイミングに基づいてタグモーダル表示を決定
      const auth = getAuth();
      if (auth.currentUser && this.clips && this.clips.length > 0) {
        // URLに直接アクセスした場合と認証後のリダイレクトでの読み込みを区別するために
        // セッションストレージを使用してリダイレクト後かどうかを判断
        const justAuthenticated = sessionStorage.getItem('justAuthenticated') === 'true';
        if (justAuthenticated) {
          this.isTagModalOpen = true;
          // フラグをリセット
          sessionStorage.removeItem('justAuthenticated');
        }
      }
    } finally {
      if (this.isLoading) {
        console.log('SharedList: 初期ローディング終了');
        this.isLoading = false;
      }
    }
  },
  methods: {
    async fetchSharedList() {
      try {
        const docId = this.$route.params.id
        const docRef = doc(db, 'shared_clips_lists', docId)
        const docSnap = await getDoc(docRef)
        
        if (docSnap.exists()) {
          this.sharedList = { id: docSnap.id, ...docSnap.data() }
          
          const clipsRef = collection(docRef, 'clips_lists')
          const clipsSnapshot = await getDocs(clipsRef)
          this.clips = clipsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }))

          // クリップデータをコンソールに出力
          console.log('取得したクリップデータ:', this.clips)
          console.log('クリップ数:', this.clips.length)
        }
      } catch (error) {
        console.error('Error fetching shared list:', error)
      }
    },
    openClipDetail(clip) {
      this.selectedClip = clip
      this.isDetailSheetOpen = true
    },
    closeClipDetail() {
      this.isDetailSheetOpen = false
      setTimeout(() => {
        this.selectedClip = null
      }, 300)
    },
    toggleNotification() {
      if (this.selectedClip && this.selectedClip.triggers?.location) {
        this.selectedClip.triggers.location.locationNotify = 
          !this.selectedClip.triggers.location.locationNotify
      }
    },
    goToHowToUse() {
      this.$router.push('/how-to-use');
    },
    async handleImportClick() {
      try {
        const auth = getAuth();
        
        // 認証状態の確認
        const user = await new Promise(resolve => {
          const unsubscribe = onAuthStateChanged(auth, user => {
            unsubscribe();
            resolve(user);
          });
        });
        
        console.log('認証状態確認:', user ? '認証済み' : '未認証');
        
        if (!user) {
          console.log('未認証のため、サインアップ画面へ遷移');
          // 認証後に戻ってきたときにタグモーダルを表示するためのフラグを設定
          sessionStorage.setItem('justAuthenticated', 'true');
          
          // 現在のURLパラメータを保持して遷移
          const currentPath = this.$route.fullPath;
          this.$router.push({
            path: '/signup',
            query: { redirect: currentPath }
          });
          return;
        }
        
        // データが取得できていることを確認
        if (this.clips && this.clips.length > 0) {
          console.log('タグ設定モーダルを表示');
          this.isTagModalOpen = true;
        } else {
          console.log('クリップデータ再取得');
          await this.fetchSharedList();
          if (this.clips && this.clips.length > 0) {
            this.isTagModalOpen = true;
          } else {
            console.error('クリップデータが取得できませんでした');
          }
        }
      } catch (error) {
        console.error('handleImportClick エラー:', error);
      }
    },
    async duplicateImageToStorage(imageUrl, userId, newClipId) {
      try {
        // Firebase Storage SDKを使用して直接画像を取得
        const storage = getStorage();
        const response = await fetch(imageUrl, {
          method: 'GET',
          mode: 'cors',
          // カスタムヘッダーを削除
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const blob = await response.blob();
        
        // 新しいパスを生成
        const timestamp = Date.now();
        const fileExtension = imageUrl.split('.').pop().split('?')[0] || 'jpg';
        const newPath = `users/${userId}/clips/${newClipId}/images/${timestamp}.${fileExtension}`;
        
        // Firebase Storageに直接アップロード
        const imageRef = storageRef(storage, newPath);
        await uploadBytes(imageRef, blob);
        
        // 新しいURLを取得
        return await getDownloadURL(imageRef);
      } catch (error) {
        console.error('画像の複製に失敗:', error);
        return imageUrl; // エラーの場合は元のURLを返す
      }
    },
    async handleImport(selectedTags, callback) {
      try {
        const auth = getAuth();
        const userId = auth.currentUser?.uid;
        
        if (!userId) {
          console.error('ユーザーが認証されていません');
          if (callback) callback();
          return;
        }

        // sharedListデータが存在しない場合、再取得を試みる
        if (!this.sharedList) {
          console.log('sharedListデータが存在しないため再取得します');
          await this.fetchSharedList();
          
          // 再取得後も存在しない場合はエラー
          if (!this.sharedList) {
            console.error('sharedListデータの取得に失敗しました');
            if (callback) callback();
            return;
          }
        }

        // shared_clips_listsドキュメントを更新
        const sharedListRef = doc(db, 'shared_clips_lists', this.sharedList.id);
        await updateDoc(sharedListRef, {
          imported_user_ids: arrayUnion(userId)
        });

        // 画像の複製処理を先に実行
        const clipPromises = this.clips.map(async (sharedClip) => {
          let newUrls = [];
          if (Array.isArray(sharedClip.url) && sharedClip.url.length > 0) {
            const urlPromises = sharedClip.url.map(url => 
              this.duplicateImageToStorage(url, userId, sharedClip.id)
            );
            newUrls = await Promise.all(urlPromises);
          }
          return { ...sharedClip, newUrls };
        });

        const processedClips = await Promise.all(clipPromises);

        // クリップを一括で保存
        for (const processedClip of processedClips) {
          const newClipRef = doc(collection(db, 'clips'));
          const documentId = newClipRef.id;

          // クリップデータを準備
          const clipData = {
            // 基本フィールド
            address: processedClip.address || '',
            address_name: processedClip.address_name || '',
            body: processedClip.body || '',
            created_at: serverTimestamp(),
            document_id: documentId,
            phoneNumber: processedClip.phoneNumber || '',
            title: processedClip.title || '',
            user_id: userId,
            updated_at: serverTimestamp(),
            url: processedClip.newUrls, // 複製した新しいURLを使用
            clip_url: processedClip.clip_url || '',

            // 共有関連フィールド
            share_owner_name: this.sharedList.owner_name || '',
            share_owner_photo_url: this.sharedList.owner_photo_url || '',
            share_list_name: this.sharedList.list_name || '',
            share_evaluation_score: processedClip.triggers?.location?.evaluation_score || 0,

            // トリガー情報
            triggers: {
              date_time: {
                end_time: processedClip.triggers?.date_time?.end_time || null,
                start_time: processedClip.triggers?.date_time?.start_time || null
              },
              location: {
                evaluation_flag: processedClip.triggers?.location?.evaluation_flag || false,
                evaluation_score: processedClip.triggers?.location?.evaluation_score || 0,
                latitude: processedClip.triggers?.location?.latitude || 0,
                longitude: processedClip.triggers?.location?.longitude || 0,
                locationNotify: processedClip.triggers?.location?.locationNotify || false
              },
              radius: processedClip.triggers?.radius || 1000,
              memos: (processedClip.triggers?.memos || []).map(memo => ({
                memo_content_url: memo.memo_content_url || [],
                content: memo.content || '',
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp()
              }))
            }
          };

          // クリップを保存
          await setDoc(newClipRef, clipData);

          // 選択されたタグをkeyoc_tagsサブコレクションに保存
          const keyocTagsRef = collection(newClipRef, 'keyoc_tags');
          for (const tagName of selectedTags) {
            const tagRef = doc(keyocTagsRef);
            await setDoc(tagRef, {
              keyoc_tag_name: tagName,
              keyoc_tag_description: '',
              keyoc_tag_timestamp: serverTimestamp()
            });
          }
        }

        // 処理完了後にcallbackを呼び出す
        if (callback) callback();
      } catch (error) {
        console.error('共有リストのインポートでエラーが発生しました:', error);
        if (callback) callback(); // エラー時もコールバックを呼び出す
      }
    },
    getImageUrl(urls) {
      // urlsが配列で、かつ最初の要素が有効なURL文字列の場合はそれを返す
      if (Array.isArray(urls) && urls.length > 0 && urls[0] && urls[0].trim() !== '') {
        return urls[0];
      }
      // それ以外の場合はフォールバック画像を返す
      return require('@/assets/image_not_found.png');
    },
    handleImageError(e) {
      e.target.src = require('@/assets/image_not_found.png');
    }
  }
}
</script>

<style scoped>
/* 既存のスタイルをそのまま維持 */
.shared-list-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.thumbnail {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.thumbnail-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
}

.list-name {
  font-size: 24px;
  color: #3C3C3C;
  margin: 20px 0;
  font-weight: bold;
}

.user-info {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.profile-image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 12px;
}

.user-details {
  display: flex;
  flex-direction: column;
}

.owner-name {
  font-size: 16px;
  color: #3C3C3C;
  margin-bottom: 4px;
}

.import-count {
  font-size: 14px;
  color: #6C6A6C;
}

.action-buttons {
  display: flex;
  align-items: center;
  margin: 20px 0;
  gap: 20px;
}

.import-button {
  background-color: #31649F;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.about-link {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #31649F;
  cursor: pointer;
  font-size: 14px;
}

.about-link i {
  font-size: 14px; /* ?アイコンのサイズを14pxに */
}

.about-text {
  text-decoration: underline;
  font-weight: bold;
}

.clips-container {
  margin-top: 30px;
}

.clip-item {
  display: flex;
  border: 1px solid #E7E7E7;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 0;
  border-bottom: none;
}

.clip-item:last-child {
  border-bottom: 1px solid #E7E7E7;
}

.clip-image-container {
  width: 200px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}

.clip-image {
  width: 100%;
  height: 150px;
}

.clip-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  background-color: #F8F9FA;
}

.evaluation {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  flex-wrap: nowrap;
}

.star-icon {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
}

.evaluation-score {
  color: #6C6A6C;
  font-size: 12px;
  flex-shrink: 0;
  margin: 0 4px;
  white-space: nowrap;
}

.no-evaluation {
  color: #6C6A6C;
  font-size: 12px;
  flex-shrink: 0;
  white-space: nowrap;
  padding: 8px;
}

.clip-content {
  flex: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 0;
  width: 100%;
  box-sizing: border-box;
}

.clip-title {
  font-size: 16px;
  color: #3C3C3C;
  margin: 0 0 8px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.clip-body {
  font-size: 14px;
  color: #6C6A6C;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
}

@media (max-width: 768px) {
  .shared-list-content {
    padding: 16px;
  }

  .thumbnail-image {
    height: 250px;
  }

  .clip-image {
    width: 200px;
    height: 150px;
  }
}

@media (max-width: 480px) {
  .shared-list-content {
    padding: 12px;
  }

  .thumbnail-image {
    height: 160px;
  }

  .list-name {
    font-size: 16px;
  }

  .owner-name {
    font-size: 14px;
  }

  .import-count {
    font-size: 12px;
  }

  .import-button {
    font-size: 10px;
    padding: 10px;
  }

  .about-link {
    font-size: 10px;
  }

  .clip-image-container {
    width: 25%;
    min-width: 25%;
  }

  .clip-image {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
  }

  .clip-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .star-icon {
    width: 10px;
    height: 10px;
  }

  .evaluation-score, .no-evaluation {
    font-size: 8px;
  }

  .clip-content {
    padding: 12px;
    margin-right: 0;
    width: 75%;
  }

  .clip-title {
    font-size: 12px;
  }

  .clip-body {
    font-size: 10px;
  }

  .clips-container {
    width: 100%;
  }

  .clip-item {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }

  .clips-container .clip-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .clips-container .clip-item:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-container {
  text-align: center;
}

.loading-gif {
  width: 80px;
  height: 80px;
  margin-bottom: 16px;
}

.loading-container span {
  display: block;
  color: #333;
  font-size: 14px;
}
</style>