<template>
  <transition name="sheet">
    <div 
      v-if="isOpen"
      class="clip-detail-sheet"
      :class="{ 'sheet-open': isOpen }"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
    >
      <div class="sheet-overlay" @click="closeSheet"></div>
      <div class="sheet-container" :style="sheetStyle">
        <!-- ローディングオーバーレイをsheet-container内に移動 -->
        <LoadingOverlay 
          v-if="isLoading"
          :progress="loadingProgress"
          class="modal-loading"
        />

        <div class="sheet-header">
          <div class="close-button" @click="closeSheet">
            <i class="fas fa-times"></i>
          </div>
        </div>

        <div class="sheet-content">
          <!-- タイトルと評価 -->
          <div class="content-header">
            <h2 class="title">{{ clip.title }}</h2>
            <div class="evaluation" v-if="clip.triggers?.location?.evaluation_score > 0">
              <div class="star-icons-container">
                <img 
                  v-for="n in 5"
                  :key="n"
                  :src="getStarImage(n <= clip.triggers.location.evaluation_score)"
                  :alt="n <= clip.triggers.location.evaluation_score ? 'filled star' : 'empty star'"
                  class="star-icon"
                />
              </div>
              <span class="evaluation-score">({{ clip.triggers.location.evaluation_score }})</span>
            </div>
          </div>

          <!-- 画像ギャラリー -->
          <div class="image-gallery">
            <div class="gallery-scroller" ref="galleryScroller">
              <template v-if="hasValidImages">
                <div 
                  v-for="(_, groupIndex) in Math.ceil(validUrls.length / 3)" 
                  :key="groupIndex"
                  class="image-group"
                >
                  <!-- 大きい画像（1枚目） -->
                  <div 
                    v-if="validUrls[groupIndex * 3]"
                    class="gallery-item large"
                  >
                    <img 
                      :src="validUrls[groupIndex * 3]" 
                      :alt="clip.title"
                      @error="handleImageError"
                    >
                  </div>

                  <!-- 小さい画像を縦に並べるコンテナ -->
                  <div class="small-images-container">
                    <!-- 2枚目 -->
                    <div 
                      v-if="validUrls[groupIndex * 3 + 1]"
                      class="gallery-item small"
                    >
                      <img 
                        :src="validUrls[groupIndex * 3 + 1]" 
                        :alt="clip.title"
                        @error="handleImageError"
                      >
                    </div>
                    <!-- 3枚目 -->
                    <div 
                      v-if="validUrls[groupIndex * 3 + 2]"
                      class="gallery-item small"
                    >
                      <img 
                        :src="validUrls[groupIndex * 3 + 2]" 
                        :alt="clip.title"
                        @error="handleImageError"
                      >
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <!-- 画像がない場合のフォールバック表示 -->
                <div class="image-group">
                  <div class="gallery-item large">
                    <img 
                      :src="require('@/assets/image_not_found.png')" 
                      alt="No image available"
                      class="fallback-image"
                    >
                  </div>
                </div>
              </template>
            </div>
          </div>

          <!-- 位置情報 -->
          <div class="location-section">
            <div class="location-info">
              <i class="fas fa-map-marker-alt location-icon"></i>
              <span class="address">{{ clip.address }}</span>
            </div>
          </div>

          <!-- リンク情報 -->
          <div class="location-section" v-if="clip.clip_url">
            <div class="location-info">
              <i class="fas fa-link location-icon"></i>
              <a 
                :href="clip.clip_url" 
                target="_blank" 
                rel="noopener noreferrer" 
                class="clip-url"
              >
                {{ clip.clip_url }}
              </a>
            </div>
          </div>

          <!-- 作成日時 -->
          <div class="date-section">
            {{ formattedDate }}
          </div>

          <!-- 本文 -->
          <div class="body-section">
            <p class="body-content">{{ clip.body }}</p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import LoadingOverlay from '@/components/Common/LoadingOverlay.vue'

export default {
  name: 'ClipDetailSheet',
  components: {
    LoadingOverlay
  },
  props: {
    clip: {
      type: Object,
      required: true
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      touchStartY: 0,
      currentTranslateY: 0,
      isDragging: false,
      isMobileView: false,
      isLoading: false,
      loadingProgress: 0
    }
  },
  computed: {
    sheetStyle() {
      return {
        transform: `translateY(${this.currentTranslateY}px)`
      }
    },
    formattedDate() {
      if (!this.clip.created_at) {
        console.log('created_at is missing:', this.clip.created_at)
        return ''
      }
      
      if (this.clip.created_at.seconds) {
        const date = new Date(this.clip.created_at.seconds * 1000)
        console.log('Formatted from timestamp:', date)
        return this.formatDate(date)
      }
      
      const date = new Date(this.clip.created_at)
      console.log('Formatted from date string:', date)
      return this.formatDate(date)
    },
    validUrls() {
      if (!this.clip.url || !Array.isArray(this.clip.url)) return [];
      return this.clip.url.filter(url => url && url.trim() !== '');
    },
    hasValidImages() {
      return this.validUrls.length > 0;
    }
  },
  created() {
    this.checkViewport()
    window.addEventListener('resize', this.checkViewport)
  },
  destroyed() {
    window.removeEventListener('resize', this.checkViewport)
  },
  methods: {
    formatDate(date) {
      if (!(date instanceof Date) || isNaN(date)) {
        console.log('Invalid date object:', date)
        return ''
      }
      
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      const day = days[date.getDay()]
      const dd = String(date.getDate()).padStart(2, '0')
      const mm = String(date.getMonth() + 1).padStart(2, '0')
      const yyyy = date.getFullYear()
      
      return `${day}, ${dd}/${mm}/${yyyy}`
    },
    getStarImage(isFilled) {
      try {
        return require(`@/assets/${isFilled ? 'star_gold.svg' : 'star.svg'}`);
      } catch (error) {
        console.error('Error loading star image:', error);
        return '';
      }
    },
    checkViewport() {
      this.isMobileView = window.innerWidth <= 480
    },
    handleTouchStart(e) {
      if (!this.isMobileView) return
      this.touchStartY = e.touches[0].clientY
      this.isDragging = true
    },
    handleTouchMove(e) {
      if (!this.isMobileView) return
      if (!this.isDragging) return
      const deltaY = e.touches[0].clientY - this.touchStartY
      if (deltaY > 0) {
        this.currentTranslateY = deltaY
        e.preventDefault()
      }
    },
    handleTouchEnd() {
      if (!this.isMobileView) return
      if (this.currentTranslateY > 150) {
        this.closeSheet()
      } else {
        this.currentTranslateY = 0
      }
      this.isDragging = false
    },
    closeSheet() {
      this.$emit('close')
    },
    handleImageError(e) {
      e.target.src = require('@/assets/image_not_found.png');
    },
    startLoading() {
      this.isLoading = true
      this.loadingProgress = 0
      // プログレスバーのシミュレーション
      const interval = setInterval(() => {
        if (this.loadingProgress < 100) {
          this.loadingProgress += Math.random() * 15
          if (this.loadingProgress > 100) {
            this.loadingProgress = 100
          }
        } else {
          clearInterval(interval)
          this.isLoading = false
        }
      }, 500)
    }
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
        this.currentTranslateY = 0
      }
    },
    clip: {
      immediate: true,
      handler(newClip) {
        if (newClip?.created_at) {
          console.log('Raw created_at value:', newClip.created_at)
        }
      }
    }
  }
}
</script>

<style scoped>
.clip-detail-sheet {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.sheet-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.sheet-container {
  position: relative;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.sheet-header {
  padding: 12px;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.close-button {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #6C6A6C;
}

.sheet-content {
  flex: 1;
  overflow-y: auto;
  padding: 0 20px 20px;
}

.content-header {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 20px;
}

.title {
  margin: 0;
  font-size: 18px;
  color: #3C3C3C;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.evaluation {
  display: flex;
  gap: 4px;
  align-items: center;
  white-space: nowrap;
}

.star-icons-container {
  display: flex;
  gap: 4px;
}

.star-icon {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
}

.evaluation-score {
  color: #6C6A6C;
  font-size: 12px;
  margin-left: 4px;
}

.image-gallery {
  margin: 0 -20px;
  overflow: hidden;
}

.gallery-scroller {
  display: flex;
  gap: 8px;
  padding: 0 20px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.image-group {
  display: flex;
  gap: 8px;
  flex-shrink: 0;
}

.small-images-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.gallery-item {
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
}

.gallery-item.large {
  width: 240px;
  height: 240px;
}

.gallery-item.small {
  width: 120px;
  height: 116px;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #F8F9FA;
}

.gallery-scroller::-webkit-scrollbar {
  height: 4px;
}

.gallery-scroller::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.gallery-scroller::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

.location-section {
  margin: 20px 0;
  padding: 16px 0;
  border-top: 1px solid #E7E7E7;
  border-bottom: 1px solid #E7E7E7;
}

.location-info {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.location-icon {
  color: #CA963F;
  margin-top: 4px;
}

.address, .clip-url {
  font-size: 14px;
  line-height: 1.5;
  word-break: break-all;
  flex: 1;
}

.clip-url {
  color: #31649F;
  text-decoration: underline;
}

.date-section {
  text-align: right;
  color: #6C6A6C;
  font-size: 8px;
  margin: 8px 0;
}

.body-section {
  padding: 16px;
  background-color: #F8F9FA;
  border: 1px solid #E7E7E7;
  border-radius: 8px;
}

.body-content {
  font-size: 14px;
  line-height: 1.6;
  color: #3C3C3C;
  margin: 0;
}

.fallback-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #F8F9FA;
}

/* PC表示時のスタイル */
@media (min-width: 481px) {
  .clip-detail-sheet {
    justify-content: center;
    align-items: center;
  }

  .sheet-container {
    width: 90%;
    max-width: 800px;
    height: 90vh;
    border-radius: 8px;
    transform: translateY(0) !important;
  }

  .sheet-enter-active,
  .sheet-leave-active {
    transition: opacity 0.3s ease;
  }

  .sheet-enter-from,
  .sheet-leave-to {
    opacity: 0;
  }

  .sheet-enter-from .sheet-container,
  .sheet-leave-to .sheet-container {
    transform: scale(0.9);
  }

  .modal-loading {
    border-radius: 8px;
  }
}

/* モバイル表示時のスタイル */
@media (max-width: 480px) {
  .sheet-container {
    border-radius: 20px 20px 0 0;
    height: 100vh;
    max-height: 95vh;
    width: 100%;
    transform: translateY(100%);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .sheet-enter-active {
    transition: opacity 0.3s ease;
  }

  .sheet-enter-active .sheet-container {
    transform: translateY(0);
  }

  .sheet-leave-active {
    transition: opacity 0.3s ease;
  }

  .sheet-leave-active .sheet-container {
    transform: translateY(100%);
  }

  .sheet-enter-from,
  .sheet-leave-to {
    opacity: 0;
  }

  .gallery-item.large {
    width: 180px;
    height: 180px;
  }

  .gallery-item.small {
    width: 90px;
    height: 87px;
  }

  .address, .clip-url {
    font-size: 12px;
  }

  .body-content {
    font-size: 12px;
  }
}

/* ローディングオーバーレイのスタイルを追加 */
.modal-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px 20px 0 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1001;
}
</style>
```