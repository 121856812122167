import Vue from 'vue'
import Vuex from 'vuex'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    loading: true
  },
  mutations: {
    setUser(state, user) {
      // 状態を更新
      state.user = user;
      
      // ローカルストレージも同時に更新
      if (user) {
        localStorage.setItem('auth_user', JSON.stringify(user));
      } else {
        localStorage.removeItem('auth_user');
      }
    },
    setLoading(state, loading) {
      console.log('Mutation setLoading called with:', loading);
      state.loading = loading;
      console.log('New state after setLoading:', state.loading);
    }
  },
  actions: {
    initAuth({ commit }) {
      const auth = getAuth();
      
      // 認証状態の監視を開始
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // 認証情報をストアとローカルストレージに保存
          const userData = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName || 'Apple User', // Appleユーザー対応
            photoURL: user.photoURL || '/default-avatar.png' // デフォルトアバター
          };
          commit('setUser', userData);
          localStorage.setItem('auth_user', JSON.stringify(userData));
        } else {
          commit('setUser', null);
          localStorage.removeItem('auth_user');
        }
        commit('setLoading', false);
      });
  
      // ページロード時に保存された認証情報を復元
      const savedUser = localStorage.getItem('auth_user');
      if (savedUser) {
        commit('setUser', JSON.parse(savedUser));
      }
    }
  },
  getters: {
    isAuthenticated: state => {
      console.log('Getter isAuthenticated called, returning:', !!state.user);
      return !!state.user;
    },
    currentUser: state => {
      console.log('Getter currentUser called, returning:', state.user);
      return state.user;
    }
  }
})