<template>
  <div class="modal-overlay">
    <div class="modal-content">
      <div class="complete-content">
        <div class="complete-icon">
          <i class="fas fa-check-circle"></i>
        </div>
        <div class="complete-text">
          記憶の取り込みが完了しました
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImportCompleteModal'
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  width: 90%;
  max-width: 500px;
  border-radius: 12px;
  padding: 20px;
  max-height: 90vh;
  overflow-y: auto;
}

.complete-content {
  text-align: center;
  padding: 24px;
}

.complete-icon {
  font-size: 48px;
  color: #31649F;
  margin-bottom: 16px;
}

.complete-text {
  font-size: 16px;
  color: #333;
}

@media (max-width: 480px) {
  .modal-content {
    width: 95%;
    margin: 20px;
    padding: 16px;
  }

  .complete-content {
    padding: 16px;
  }

  .complete-icon {
    font-size: 36px;
  }

  .complete-text {
    font-size: 14px;
  }
}
</style> 